import React from 'react';
import './App.css';

function App() {
  return (
    <div>
      <header>
        <h1>Onepay API Documentation</h1>
        <p>Version 3.0 | Clean and Secure Payment Integration</p>
      </header>
      <nav>
        <a href="#authentication">Authentication</a>
        <a href="#request-body">Request Body</a>
        <a href="#request-link">Checkout API</a>
        <a href="#callback">Callback Response</a>
        <a href="#test-details">Test Details</a>
      </nav>
      <div className="container">
        <h2 id="authentication">Authentication</h2>
        <p>To authenticate API requests, include your App Token in the <code>Authorization</code></p>
        <pre>
          {`Authorization: <YOUR_APP_TOKEN>`}
        </pre>


        <h2 id="request-body">Request Body</h2>
        <p>• Please use the following JSON body structure when making a request.</p>
        <p>• The amount must be a decimal value formatted to two decimal places.</p>
        <p>• To generate the hash, begin by concatenating the values of app_id, currency, and amount in the specified order, followed by appending your unique hash salt {"<<YOUR HASH SALT>>"} (<code>{'app_id+currency+amount+<<YOUR HASH SALT>>'}</code>) to the resulting string. Once the concatenation is complete, apply the SHA-256 algorithm to the constructed string. The resulting output should be used as the hash string when sending the request.</p>

        <pre>{`{
    "currency":"LKR", --> LRK OR USD
    "amount":100.00, --> The amount must be at least 100 for LKR or 1 for USD
    "app_id":"<<YOUR APP ID>>",
    "reference":"<<10-characters>>",
    "customer_first_name":"Johe",
    "customer_last_name":"Dohe",
    "customer_phone_number":"+94771234567",
    "customer_email":"onepay@spemai.com",
    "transaction_redirect_url":"<<YOUR REDIRECT URL>>",
    "hash":"xxxxxxxxxxxxxxxxxxx"
    "additional_data":"<<PROVIDE STRING(OPTIONAL)>>"
}`}</pre>


        <h2 id="request-link">Checkout Request</h2>
        <p>Use this endpoint to create a checkout link for your customers.</p>
        <pre>{`POST https://api.onepay.lk/v3/checkout/link/`}</pre>

        <h3>Request Example</h3>
        <div className="code-snippet">
          <strong>cURL:</strong>
          <pre>
            {`curl --location 'https://api.onepay.lk/v3/checkout/link/' 
--header 'Content-Type: application/json' 
--header 'Authorization: <<YOUR APP TOKEN>>' 
--data-raw '{
    "currency":"LKR",
    "amount": 100.00, 
    "app_id":"<<YOUR APP ID>>",
    "reference":"7Q1M1187AEB78CB94D5A5",
    "customer_first_name":"Johe",
    "customer_last_name":"Dohe",
    "customer_phone_number":"+94771234567",
    "customer_email":"onepay@spemai.com",
    "transaction_redirect_url":"https://developer.onepay.lk/",
    "hash": "<<YOUR HASH STRING>>",
    "additional_data":"jjj"
}'`}
          </pre>
        </div>

        <h3>Response Example</h3>
        <div className="code-snippet">
          <strong>JSON</strong>
          <pre>
            {`{
    "status": 200,
    "message": "Successfully generate checkout link",
    "data": {
        "ipg_transaction_id": "XXXXXXXXXXXXXXXX",
        "amount": {
            "gross_amount": 100.00,
            "discount": 0,
            "handling_fee": 0,
            "net_amount": 100.0,
            "currency": "LKR"
        },
        "gateway": {
            "redirect_url": "https://payment.onepay.lk/redirect/XXXXXXXXXX"
        }
    }
}`}
          </pre>
        </div>

        <h2 id="callback">Callback Response</h2>
        <p>Update Callback URL In your portal, provide the callback URL in the APP section, where your application can accept POST requests with a JSON payload.</p>
        <p>Once completed transaction in the Onepay side we will call you the callback URL, the callback will provide details of the transaction, which can be useful for logging, verifying, and updating the transaction status in your system.</p>
        <div className="code-snippet">
          <strong>JSON</strong>
          <pre>
            {`{
    "transaction_id": "WQBV118E584C83CBA50C6",
    "status": 1,
    "status_message": "SUCCESS",
    "additional_data": ""
}`}
          </pre>
        </div>

        <h2 id="error-codes">Error Codes</h2>
        <table>
          <thead>
            <tr>
              <th>Code</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><code>200</code></td>
              <td>The checkout link has been successfully created</td>
            </tr>
            <tr>
              <td><code>401</code></td>
              <td>The Authorization header is either missing, contains an invalid application token, or includes an incorrect hash</td>
            </tr>
            <tr>
              <td><code>400</code></td>
              <td>The request body is invalid due to one or more of the following errors. Detailed error information will be included in the response.
                <ul>
                  <li>Invalid app id</li>
                  <li>Invalid amount</li>
                  <li>Monthly subscription quote exceed</li>
                  <li>Invalid app state</li>
                  <li>Currency type not available for app</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td><code>429</code></td>
              <td>Too many requests have been made within a one second period</td>
            </tr>
          </tbody>
        </table>
        <h2 id="test-details">Best Practices</h2>
        <p>Using our APIs, you can reduce latency by deploying your service in the Asia region, ensuring faster response times and improved performance for users in that area.</p>
        <p>Our API enforces a rate limit of 500 requests per second to ensure optimal performance and fair usage. If this threshold is exceeded, the server responds with a <code>429</code> status code, indicating <code>Too Many Requests</code>. This mechanism helps maintain service stability and prevents overloading.</p>

        <h2 id="test-details">Test Card Details</h2>
        <p>Use the following test card details for integration testing:</p>
        <table>
          <thead>
            <tr>
              <th>Card Type</th>
              <th>Card Number</th>
              <th>Expiration Date</th>
              <th>CVV</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Visa</td>
              <td>
                <div>4508750015741019</div>
                <div>4012000033330026</div>
              </td>
              <td>01/39</td>
              <td>100</td>
            </tr>
            <tr>
              <td>Master</td>
              <td>
                <div>5123450000000008</div>
                <div>5111111111111118</div>
              </td>
              <td>01/39</td>
              <td>100</td>
            </tr>
          </tbody>
        </table>

        <h2 id="test-details">Plugins</h2>
        <p><a href="https://wordpress.org/plugins/onepay-payment-gateway-for-woocommerce/" target="_blank" rel="noopener noreferrer">WooCommerce</a></p>
        <p><a href="https://apps.shopify.com/onepay-payment-gateway" target="_blank" rel="noopener noreferrer">Shopify</a></p>
        <p><a href="https://www.opencart.com/index.php?route=marketplace/extension/info&member_token=54ce0334336cf13da7e0dabf3ff72632&extension_id=43995" target="_blank" rel="noopener noreferrer">OpenCart</a></p>
        <p>For additional sample codes and resources, please visit our official <a href="https://github.com/onepay-srilanka" target="_blank" rel="noopener noreferrer"> GitHub </a>repository.</p>

      </div>

      <footer>
        &copy; 2024 Onepay. All Rights Reserved.
      </footer>
    </div>
  );
}

export default App;
